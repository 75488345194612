import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e7f4e0d = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _24d0a772 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _e064bcec = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1ee7d870 = () => interopDefault(import('../pages/search/documents.vue' /* webpackChunkName: "pages/search/documents" */))
const _91864248 = () => interopDefault(import('../pages/search/products.vue' /* webpackChunkName: "pages/search/products" */))
const _e2fa44fa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2cc8acc5 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _979e1d26 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _8b64f3ca = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _e667878a = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _2e7f4e0d,
    name: "catalog___ru"
  }, {
    path: "/search",
    component: _24d0a772,
    children: [{
      path: "",
      component: _e064bcec,
      name: "search___ru"
    }, {
      path: "documents",
      component: _1ee7d870,
      name: "search-documents___ru"
    }, {
      path: "products",
      component: _91864248,
      name: "search-products___ru"
    }]
  }, {
    path: "/",
    component: _e2fa44fa,
    name: "index___ru"
  }, {
    path: "/catalog/:slug",
    component: _2cc8acc5,
    name: "catalog-slug___ru"
  }, {
    path: "/news/:slug?",
    component: _979e1d26,
    name: "news-slug___ru"
  }, {
    path: "/product/:slug?",
    component: _8b64f3ca,
    name: "product-slug___ru"
  }, {
    path: "/:slug",
    component: _e667878a,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
